import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"

const AboutPageStyles = styled.div`
  min-height: calc(100vh - 245px);
  background: #fff;

  .section {
    padding: 1em 3em;
    h1 {
      font-family: "hand-of-sean", Arial, Helvetica, sans-serif;
      color: #000;
      margin: 0;
      padding-top: 1em;
      text-align: center;
    }

    p {
      font-family: "tv-nord", Arial, Helvetica, sans-serif;
    }
  }
`

export default function AboutPage({ data, location }) {
  const siteTitle = "About"
  const pageData = data.file.childMdx.frontmatter

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={siteTitle}
        keywords={[
          `carbon wheels`,
          `carbon fiber wheels`,
          `carbon bicycle wheels`,
          `high performance bicycle wheels`,
          `bike wheels`,
          `bmx cruiser wheels`,
          `29er bmx`,
          `fixed gear wheels`,
          `fixed wheels`,
          `fixie wheels`,
          `track wheels`,
          `road bike wheels`,
          `carbon wheelset`,
          `usa made wheels`,
        ]}
      />
      <AboutPageStyles>
        <div className="section">
          <h1>{pageData.whatWeDo.title}</h1>
          <p>{pageData.whatWeDo.description}</p>
        </div>
        <div className="img-wrapper">
          <GatsbyImage
            image={pageData.bannerImage.childImageSharp.gatsbyImageData}
            alt={pageData.bannerImage.name}
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
        <div className="section">
          <h1>{pageData.howWeDoIt.title}</h1>
          <p>{pageData.howWeDoIt.description}</p>
        </div>
      </AboutPageStyles>
    </Layout>
  )
}

export const query = graphql`
  query AboutPageQuery {
    file(name: { eq: "about" }) {
      name
      childMdx {
        id
        frontmatter {
          templateKey
          whatWeDo {
            title
            description
          }
          bannerImage {
            id
            name
            childImageSharp {
              gatsbyImageData(quality: 90, height: 500, layout: FULL_WIDTH)
            }
          }
          howWeDoIt {
            title
            description
          }
        }
      }
    }
  }
`
